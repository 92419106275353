html,
body,
#root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0.5em;
  min-width: 375px;
  width: 100%;
  min-height: 600px;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  background-color: #022c43;
  color: #ececec;
  box-sizing: border-box;
}
.drum-machine {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  background-color: #115173;
  width: 100%;
  height: 100%;
  border: 4px solid #ffd700;
  padding: 1em;
  box-sizing: border-box;
}
.drum-pad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #022c43;
  height: 60%;
  width: 100%;
  padding: 1em 0.5em;
  box-sizing: border-box;
}
.drum-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5em;
}
.drum-pad {
  width: 100%;
  height: 100%;
  margin: 0 8px;
  background-color: #ffd700;
  color: #022c43;
  border-radius: 5%;
  border: none;
  box-sizing: border-box;
  font-size: 2em;
  padding: 0;
  cursor: pointer;
}
button:hover {
  background-color: #ffe033;
}
button:focus {
  outline: none;
}
button:active,
.active {
  background-color: #ffe033;
  box-shadow: inset 0 0 8px #806c00;
}
.display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40%;
}
.display {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: 2em;
  height: 40%;
  background-color: #022c43;
}
.controls-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 60%;
}
.bank-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bank-label {
  padding: 0;
  margin: 0;
  padding-bottom: 0.5em;
  text-align: center;
  font-weight: 300;
  font-size: 12px;
}
.bank {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 54px;
}
.bank input {
  opacity: 0;
  width: 0;
  height: 0;
}
.bank-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffd700;
  transition: 0.4s;
}
.bank-toggle:before {
  position: absolute;
  content: "";
  width: 46px;
  height: 46px;
  left: 4px;
  bottom: 4px;
  background-color: #022c43;
  transition: 0.4s;
}
input:checked + .bank-toggle {
  background-color: #022c43;
}
input:focus + .bank-toggle {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .bank-toggle:before {
  background-color: #ffd700;
  transform: translateX(46px);
}
.mute-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mute {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffd700;
  border: none;
  cursor: pointer;
}
.mute i {
  font-size: 2em;
  color: #022c43;
}

@media only screen and (min-width: 600px) {
  .drum-machine {
    flex-direction: row;
    background-color: #115173;
    min-width: 600px;
    max-width: 800px;
    height: 400px;
  }
  .drum-pad-container {
    height: 100%;
    width: 60%;
    margin-right: 1em;
  }
  .display-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    height: 100%;
    padding: 1em 0;
    box-sizing: border-box;
  }
  .display {
    height: 20%;
  }
  .controls-container {
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    height: 80%;
  }
  .mute-container {
    height: 100%;
  }
}